import React, { useState } from 'react';
import { Card, CardBody, Col, Dropdown, DropdownMenu, DropdownToggle, Row } from 'reactstrap';

interface MagicItem {
  magic: number;
  profit: number;
  operations: number;
}

interface DailyResultsPopoverProps {
  dailyResults?: {
    date: string;
    totalProfit: number;
    totalOperations: number;
    profitableOperations: number;
    unprofitableOperations: number;
    winRate: number;
    maxProfit: number;
    maxLoss: number;
    magicDetails?: Array<MagicItem>;
  };
}

const DailyResultsPopover: React.FC<DailyResultsPopoverProps> = ({ dailyResults }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const formatDate = (dateString: string) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    return date.toLocaleDateString('pt-BR');
  };

  const getLucroStyle = (lucro: number | null) => {
    if (lucro === null) return 'text-warning';
    if (lucro > 0) return 'text-success';
    if (lucro < 0) return 'text-danger';
    return 'text-warning';
  };

  const getMagicNumbersGroups = () => {
    if (!dailyResults?.magicDetails || dailyResults.magicDetails.length === 0) {
      return { profitable: [], unprofitable: [] };
    }

    const grouped = dailyResults.magicDetails.reduce((acc: Record<string, MagicItem>, item) => {
      const key = item.magic.toString();

      if (!acc[key]) {
        acc[key] = {
          magic: item.magic,
          profit: 0,
          operations: 0,
        };
      }

      acc[key].profit += item.profit;
      acc[key].operations += item.operations;

      return acc;
    }, {});

    const profitableList: MagicItem[] = [];
    const unprofitableList: MagicItem[] = [];

    for (const key in grouped) {
      if (grouped[key].profit > 0) {
        profitableList.push(grouped[key]);
      } else if (grouped[key].profit < 0) {
        unprofitableList.push(grouped[key]);
      }
    }

    profitableList.sort((a, b) => b.profit - a.profit);
    unprofitableList.sort((a, b) => a.profit - b.profit);

    return { profitable: profitableList, unprofitable: unprofitableList };
  };

  const magicGroups = getMagicNumbersGroups();
  const lucroHoje = dailyResults?.totalProfit || 0;
  const lucroStyle = getLucroStyle(lucroHoje);

  const formattedValue = lucroHoje.toLocaleString('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  });

  return (
    <Dropdown isOpen={isOpen} toggle={toggleDropdown} className="ms-2 d-inline-block">
      <DropdownToggle tag="span" className={`${lucroStyle} fw-semibold cursor-pointer`} style={{ textDecoration: 'underline dotted' }}>
        {formattedValue}
      </DropdownToggle>
      <DropdownMenu className="p-0 dropdown-menu-end shadow-lg" style={{ minWidth: '380px' }}>
        <div className="p-3 bg-primary text-white">
          <Row className="align-items-center">
            <Col>
              <h6 className="m-0 fw-semibold text-white">Resultado de {formatDate(dailyResults?.date || '')}</h6>
            </Col>
          </Row>
        </div>

        <div className="p-3">
          <Card className="mb-0 border-0">
            <CardBody className="p-0">
              <table className="table mb-3">
                <tbody>
                  <tr>
                    <td className="py-2 text-muted border-bottom border-dashed" style={{ width: '65%' }}>
                      Total de Operações:
                    </td>
                    <td className="py-2 text-end fw-medium border-bottom border-dashed" style={{ width: '35%' }}>
                      {dailyResults?.totalOperations || 0}
                    </td>
                  </tr>
                  <tr>
                    <td className="py-2 text-muted border-bottom border-dashed">Operações com Lucro:</td>
                    <td className="py-2 text-end fw-medium border-bottom border-dashed">{dailyResults?.profitableOperations || 0}</td>
                  </tr>
                  <tr>
                    <td className="py-2 text-muted border-bottom border-dashed">Operações com Prejuízo:</td>
                    <td className="py-2 text-end fw-medium border-bottom border-dashed">{dailyResults?.unprofitableOperations || 0}</td>
                  </tr>
                  <tr>
                    <td className="py-2 text-muted border-bottom border-dashed">Taxa de Acerto:</td>
                    <td className="py-2 text-end fw-medium border-bottom border-dashed">{dailyResults?.winRate ? `${Math.round(dailyResults.winRate)}%` : '0%'}</td>
                  </tr>
                  <tr>
                    <td className="py-2 text-muted border-bottom border-dashed">Maior Lucro:</td>
                    <td className="py-2 text-end fw-medium text-success border-bottom border-dashed">
                      {(dailyResults?.maxProfit || 0).toLocaleString('pt-BR', {
                        style: 'currency',
                        currency: 'BRL',
                      })}
                    </td>
                  </tr>
                  <tr>
                    <td className="py-2 text-muted">Maior Prejuízo:</td>
                    <td className="py-2 text-end fw-medium text-danger">
                      {(dailyResults?.maxLoss || 0).toLocaleString('pt-BR', {
                        style: 'currency',
                        currency: 'BRL',
                      })}
                    </td>
                  </tr>
                </tbody>
              </table>

              {(magicGroups.profitable.length > 0 || magicGroups.unprofitable.length > 0) && (
                <div className="border-top pt-3">
                  <h6 className="fw-semibold fs-14 text-center mb-3">Magic Numbers</h6>

                  {magicGroups.profitable.length > 0 && (
                    <div className="mb-3">
                      <h6 className="text-success mb-2 fs-13">
                        <i className="ri-arrow-up-circle-fill me-1"></i>
                        Lucrativos
                      </h6>
                      <div className="table-responsive border rounded" style={{ maxHeight: '150px' }}>
                        <table className="table table-sm mb-0">
                          <thead className="table-light">
                            <tr>
                              <th className="fs-12" style={{ width: '45%' }}>
                                Magic
                              </th>
                              <th className="fs-12 text-center" style={{ width: '20%' }}>
                                Ops.
                              </th>
                              <th className="fs-12 text-end pe-3" style={{ width: '35%' }}>
                                Lucro
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {magicGroups.profitable.map((item, idx) => (
                              <tr key={`p-${item.magic}-${idx}`} className={idx < magicGroups.profitable.length - 1 ? 'border-bottom' : ''}>
                                <td className="py-1 fs-12 fw-medium ps-3">{item.magic}</td>
                                <td className="py-1 fs-12 text-center">{item.operations}</td>
                                <td className="py-1 fs-12 text-end text-success pe-3">
                                  {item.profit.toLocaleString('pt-BR', {
                                    style: 'currency',
                                    currency: 'BRL',
                                  })}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  )}

                  {magicGroups.unprofitable.length > 0 && (
                    <div className="mb-0">
                      <h6 className="text-danger mb-2 fs-13">
                        <i className="ri-arrow-down-circle-fill me-1"></i>
                        Não Lucrativos
                      </h6>
                      <div className="table-responsive border rounded" style={{ maxHeight: '150px' }}>
                        <table className="table table-sm mb-0">
                          <thead className="table-light">
                            <tr>
                              <th className="fs-12" style={{ width: '45%' }}>
                                Magic
                              </th>
                              <th className="fs-12 text-center" style={{ width: '20%' }}>
                                Ops.
                              </th>
                              <th className="fs-12 text-end pe-3" style={{ width: '35%' }}>
                                Prejuízo
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {magicGroups.unprofitable.map((item, idx) => (
                              <tr key={`u-${item.magic}-${idx}`} className={idx < magicGroups.unprofitable.length - 1 ? 'border-bottom' : ''}>
                                <td className="py-1 fs-12 fw-medium ps-3">{item.magic}</td>
                                <td className="py-1 fs-12 text-center">{item.operations}</td>
                                <td className="py-1 fs-12 text-end text-danger pe-3">
                                  {item.profit.toLocaleString('pt-BR', {
                                    style: 'currency',
                                    currency: 'BRL',
                                  })}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </CardBody>
          </Card>
        </div>
      </DropdownMenu>
    </Dropdown>
  );
};

export default DailyResultsPopover;
