import { allRevenueData, currentYearDeviceData, lastMonthDeviceData, lastWeekDeviceData, todayDeviceData } from 'common/data';
import { APIClient } from './api_helper';

import * as url from './url_helper';
import { getUidFromSessionStorage } from 'slices/thunks';

const api = new APIClient();

// Gets the logged in user data from local session
export const getLoggedInUser = () => {
  const user = localStorage.getItem('user');
  if (user) return JSON.parse(user);
  return null;
};

// //is user is logged in
export const isUserAuthenticated = () => {
  return getLoggedInUser() !== null;
};

// Register Method
export const postFakeRegister = (data: any) => api.create(url.POST_FAKE_REGISTER, data);

// Login Method
export const postFakeLogin = (data: any) => api.create(url.POST_FAKE_LOGIN, data);

// postForgetPwd
export const postFakeForgetPwd = (data: any) => api.create(url.POST_FAKE_PASSWORD_FORGET, data);

// Edit profile
export const postJwtProfile = (data: any) => api.create(url.POST_EDIT_JWT_PROFILE, data);

export const postFakeProfile = (data: any) => api.update(url.POST_EDIT_PROFILE + '/' + data.idx, data);

// Register Method
export const postJwtRegister = (url: string, data: any) => {
  return api.create(url, data).catch((err) => {
    var message;
    if (err.response && err.response.status) {
      switch (err.response.status) {
        case 404:
          message = 'Sorry! the page you are looking for could not be found';
          break;
        case 500:
          message = 'Sorry! something went wrong, please contact our support team';
          break;
        case 401:
          message = 'Invalid credentials';
          break;
        default:
          message = err[1];
          break;
      }
    }
    throw message;
  });
};

// Login Method
export const postJwtLogin = (data: any) => api.create(url.POST_FAKE_JWT_LOGIN, data);

// postForgetPwd
export const postJwtForgetPwd = (data: any) => api.create(url.POST_FAKE_JWT_PASSWORD_FORGET, data);

// postSocialLogin
export const postSocialLogin = (data: any) => api.create(url.SOCIAL_LOGIN, data);

export const postCreateUser = (data: any) => api.create(url.POST_CREATE_USER, data);

// Sessions by Countries
export const getAllData = () => api.get(url.GET_ALL_DATA);
export const getHalfYearlyData = () => api.get(url.GET_HALFYEARLY_DATA);
export const getMonthlyData = () => api.get(url.GET_MONTHLY_DATA);

// Audiences Metrics
export const getAllAudiencesMetricsData = () => api.get(url.GET_ALLAUDIENCESMETRICS_DATA);
export const getMonthlyAudiencesMetricsData = () => api.get(url.GET_MONTHLYAUDIENCESMETRICS_DATA);
export const getHalfYearlyAudiencesMetricsData = () => api.get(url.GET_HALFYEARLYAUDIENCESMETRICS_DATA);
export const getYearlyAudiencesMetricsData = () => api.get(url.GET_YEARLYAUDIENCESMETRICS_DATA);

// Users by Device
export const getTodayDeviceData = () => todayDeviceData;
export const getLastWeekDeviceData = () => lastWeekDeviceData;
export const getLastMonthDeviceData = () => lastMonthDeviceData;
export const getCurrentYearDeviceData = () => currentYearDeviceData;

// Audiences Sessions by Country
export const getTodaySessionData = () => api.get(url.GET_TODAYSESSION_DATA);
export const getLastWeekSessionData = () => api.get(url.GET_LASTWEEKSESSION_DATA);
export const getLastMonthSessionData = () => api.get(url.GET_LASTMONTHSESSION_DATA);
export const getCurrentYearSessionData = () => api.get(url.GET_CURRENTYEARSESSION_DATA);

// Dashboard Ecommerce
// Revenue

// Função para agrupar os dados por período (diário, mensal, semestral, anual)
const groupDataByPeriod = (data: any[], period: string, robotIds: string[]): GroupedData => {
  switch (period) {
    case 'daily':
      return groupDataBy(data, 'days', robotIds);
    case 'month':
      return groupDataBy(data, 'months', robotIds);
    case 'halfyear':
      return groupDataBy(data, 'halfYears', robotIds);
    case 'year':
      return groupDataBy(data, 'years', robotIds);
    case 'week':
      return groupDataBy(data, 'weeks', robotIds);
    default:
      return groupDataBy(data, 'days', robotIds);
  }
};

type CapitalCurveData = {
  name: string;
  type: string;
  data: number[];
  periods: string[];
  statistic: any;
};

type RevenueEntry = {
  name: string;
  type: string;
  data: number[];
  days: string[];
};

type StatisticsData = {
  profit: number;
  maxDrawdown: number;
  maxDrawdownDay: number;
  maxDrawup: number;
  maxDrawupDay: number;
};

type GroupedData = {
  groupedData: RevenueEntry[];
  categories?: string[];
  statistics?: StatisticsData;
  allDays?: string[];
  allMonths?: string[];
  allHalfYears?: string[];
  allYears?: string[];
  allWeeks?: string[];
};

const calculateDailyProfit = (data: RevenueEntry[], robotIds: string[]) => {
  const allDays = new Set<string>();
  data.forEach((entry) => entry.days.forEach((day) => allDays.add(day)));

  const dailyProfitIndex = data.findIndex((entry) => entry.name === 'Lucro dia');
  if (dailyProfitIndex === -1) {
    const dailyProfitData = Array.from(allDays).map((day) => {
      let dailyTotalProfit = 0;
      data.forEach((robot) => {
        if (robotIds.length === 0 || robotIds.includes(robot.name)) {
          const dayIndex = robot.days.findIndex((d) => d === day);
          if (dayIndex !== -1) {
            dailyTotalProfit += robot.data[dayIndex];
          }
        }
      });
      return dailyTotalProfit;
    });
    data.push({
      name: 'Lucro dia',
      type: 'bar',
      data: dailyProfitData,
      days: Array.from(allDays),
    });
  } else {
    const dailyProfitEntry = data[dailyProfitIndex];
    dailyProfitEntry.data = Array.from(allDays).map((day) => {
      let dailyTotalProfit = 0;
      data.forEach((robot) => {
        if (robotIds.length === 0 || robotIds.includes(robot.name)) {
          const dayIndex = robot.days.findIndex((d) => d === day);
          if (dayIndex !== -1) {
            dailyTotalProfit += robot.data[dayIndex];
          }
        }
      });
      return dailyTotalProfit;
    });
    data[dailyProfitIndex] = dailyProfitEntry;
  }
  return data;
};

const groupDataBy = (data: RevenueEntry[], periodType: string, robotIds: string[]): GroupedData => {
  const allPeriodsSet: Set<string> = new Set();
  let filteredData = data.filter((entry) => robotIds.length === 0 || robotIds.includes(entry.name) || entry.name === 'Lucro dia');

  // Calcula o lucro do dia para os robôs selecionados
  calculateDailyProfit(filteredData, robotIds);

  let totalProfit = 0;
  let maxDrawdown = 0;
  let maxDrawdownValue = 0;
  let maxDrawup = 0;
  let maxDrawupValue = 0;

  // Agrupa os dados pelo período especificado
  const groupedData = filteredData.map((entry) => {
    const groupedValues: { [key: string]: number[] } = {};
    entry.days.forEach((day, index) => {
      const periodValue = getPeriodValue(day, periodType);
      allPeriodsSet.add(periodValue);
      if (!groupedValues[periodValue]) {
        groupedValues[periodValue] = [];
      }
      const totalProfitOfDay = entry.data[index];
      if (entry.name !== 'Lucro dia') {
        totalProfit += totalProfitOfDay;

        if (totalProfitOfDay < 0 && totalProfitOfDay < maxDrawdown) {
          maxDrawdown = totalProfitOfDay;
          maxDrawdownValue = totalProfit; // Armazena o valor total no momento do maior drawdown
        }

        if (totalProfitOfDay > 0 && totalProfitOfDay > maxDrawup) {
          maxDrawup = totalProfitOfDay;
          maxDrawupValue = totalProfit; // Armazena o valor total no momento do maior drawup
        }
      }
      groupedValues[periodValue].push(totalProfitOfDay);
    });

    return {
      ...entry,
      data: Array.from(allPeriodsSet).map((period: string) => (groupedValues[period] ? groupedValues[period].reduce((acc, curr) => acc + curr, 0) : 0)),
      days: Array.from(allPeriodsSet).sort(),
    };
  });

  const allPeriods: string[] = Array.from(allPeriodsSet).sort();
  const statistics: StatisticsData = {
    profit: totalProfit,
    maxDrawdown: maxDrawdown,
    maxDrawdownDay: maxDrawdownValue,
    maxDrawup: maxDrawup,
    maxDrawupDay: maxDrawupValue,
  };

  return { groupedData, [periodType]: allPeriods, categories: Array.from(allPeriodsSet).sort(), statistics };
};

const getPeriodValue = (date: string, periodType: string): string => {
  switch (periodType) {
    case 'days':
      return date;
    case 'months':
      return date.substring(0, 7);
    case 'halfYears':
      return parseInt(date.substring(5, 7)) <= 6 ? '1º Semestre' : '2º Semestre';
    case 'years':
      return date.substring(0, 4);
    case 'weeks':
      return date.substring(0, 7); // Ajuste conforme sua necessidade para identificar as semanas
    default:
      return '';
  }
};

export const getSummaryByDay = async (userId: string, login: number, selectedDates: string[]): Promise<RevenueEntry[]> => {
  const params: any = {
    userId: userId,
    login: login,
    startDate: selectedDates[0],
  };

  if (selectedDates.length === 2) {
    params.endDate = selectedDates[1];
  }

  try {
    const response = await api.get2(url.GET_SUMMARY_BY_DAY, params);

    const revenueEntries: RevenueEntry[] = response.map((item: any) => ({
      name: item.name,
      type: item.type,
      data: item.data,
      days: item.days,
    }));

    return revenueEntries;
  } catch (error) {
    console.error('Error fetching summary by day data:', error);
    throw error;
  }
};

export const getCapitalCurve = async (userId: string, login: number, selectedDates: string[]): Promise<CapitalCurveData[]> => {
  const params: any = {
    userId: userId,
    login: login,
    startDate: selectedDates[0],
  };

  if (selectedDates.length === 2) {
    params.endDate = selectedDates[1];
  }

  try {
    const response = await api.get2(url.GET_CAPITAL_CURVE, params);

    const revenueEntries: CapitalCurveData[] = response.map((item: any) => ({
      name: item.name,
      type: item.type,
      data: item.data,
      dataAccumulated: item.dataAccumulated,
      periods: item.periods,
      statistic: item.statistic,
    }));

    return revenueEntries;
  } catch (error) {
    console.error('Error fetching summary by day data:', error);
    throw error;
  }
};

export const getCapitalCurveDataByMagicNumber = async (userId: string, login: number, selectedDates: string[], roboId: number): Promise<any> => {
  const params: any = {
    userId: userId,
    login: login,
    startDate: selectedDates[0],
    magicNumber: roboId,
  };

  if (selectedDates.length === 2) {
    params.endDate = selectedDates[1];
  }

  try {
    const response = await api.get2(url.GET_CAPITAL_BOT_CURVE, params);
    // Retornando a resposta diretamente, sem tentar mapear
    return response;
  } catch (error) {
    console.error('Error fetching summary by day data:', error);
    throw error;
  }
};

export const getCapitalCurveDataByPortfolioId = async (userId: string, login: number, selectedDates: string[], portfolioId: number, page?: number, pageSize?: number): Promise<any> => {
  const params: any = {
    userId: userId,
    login: login,
    startDate: selectedDates[0],
    portfolioId: portfolioId,
    page: page || 1,
    pageSize: pageSize || 7,
  };

  if (selectedDates.length === 2) {
    params.endDate = selectedDates[1];
  }

  try {
    const response = await api.get2(url.GET_CAPITAL_PORTFOLIO_CURVE, params);
    return response;
  } catch (error) {
    console.error('Error fetching summary by day data:', error);
    throw error;
  }
};

export const getSummaryPortfolioByDay = async (userId: string, login: number, selectedDates: string[]): Promise<any[]> => {
  const params: any = {
    userId: userId,
    login: login,
    startDate: selectedDates[0],
  };

  if (selectedDates.length === 2) {
    params.endDate = selectedDates[1];
  }

  try {
    return await api.get2(url.GET_SUMMARY_PORTFOLIO_BY_DAY, params);
  } catch (error) {
    console.error('Error fetching summary by day data:', error);
    throw error; // Você pode decidir lançar o erro ou retornar um array vazio
  }
};

export const getAccountsByUid = async (): Promise<any[]> => {
  try {
    return await api.get2(url.GET_USER_ACCOUNTS_BY_UID + '/' + getUidFromSessionStorage());
  } catch (error) {
    console.error('Error fetching summary by day data:', error);
    throw error; // Você pode decidir lançar o erro ou retornar um array vazio
  }
};

export const getListMagic = async (login: number): Promise<any[]> => {
  const params: any = {
    userHash: getUidFromSessionStorage(),
    login: login,
  };

  try {
    return await api.get2(url.GET_LIST_MAGIC, params);
  } catch (error) {
    console.error('Error fetching summary by day data:', error);
    throw error;
  }
};

export const getListPortfolio = async (login: number): Promise<any[]> => {
  const params: any = {
    userHash: getUidFromSessionStorage(),
    login: login,
  };

  try {
    return await api.get2(url.CREATE_PORTFOLIO, params);
  } catch (error) {
    console.error('Error fetching summary by day data:', error);
    throw error; // Você pode decidir lançar o erro ou retornar um array vazio
  }
};

export const updateMagic = async (login: number, magic: number, status: boolean): Promise<void> => {
  const params: any = {
    userHash: getUidFromSessionStorage(),
    login: login,
    magic: magic,
    status: status,
  };

  try {
    await api.put(url.UPDATE_MAGIC, params);
  } catch (error) {
    console.error('Error fetching summary by day data:', error);
    throw error;
  }
};
export type MagicData = {
  magic: number;
};

export type PortfolioData = {
  name: string;
  descricao: string;
  login: number;
  userHash: string;
  portfolioMagics: MagicData[];
};
export const createPortfolio = async (data: PortfolioData): Promise<void> => {
  try {
    await api.create(url.CREATE_PORTFOLIO + '/' + getUidFromSessionStorage(), data);
  } catch (error) {
    console.error('Error fetching summary by day data:', error);
    throw error;
  }
};

export const updatePortfolio = async (id: number, data: PortfolioData): Promise<void> => {
  try {
    await api.put(url.CREATE_PORTFOLIO + '/' + id, data);
  } catch (error) {
    console.error('Error fetching summary by day data:', error);
    throw error;
  }
};

export const getAllRevenueData = () => allRevenueData; //api.get(url.GET_MONTHREVENUE_DATA);
// export const getMonthRevenueData = () => monthRevenueData//api.get(url.GET_MONTHREVENUE_DATA);
// export const getHalfYearRevenueData = () => halfYearRevenueData//api.get(url.GET_HALFYEARREVENUE_DATA);
// export const getYearRevenueData = () => yearRevenueData//api.get(url.GET_YEARREVENUE_DATA);

// Agora, você pode usar essas funções para obter os dados agrupados de acordo com o período solicitado
//export const allData = getAllRevenueData(); // Obtenha os dados brutos
//export const monthlyData = groupDataByPeriod(allRevenueData, 'monthly'); // Agrupa os dados mensalmente
//export const halfYearlyData = groupDataByPeriod(allRevenueData, 'halfYearly'); // Agrupa os dados semestralmente
//export const yearlyData = groupDataByPeriod(allRevenueData, 'yearly'); // Agrupa os dados anualmente

export const getListMonteCarloByUserId = async (): Promise<any[]> => {
  try {
    return await api.get2(url.GET_LIST_MONTE_CARLO + '/' + getUidFromSessionStorage());
  } catch (error) {
    console.error('Error fetching summary by day data:', error);
    throw error; // Você pode decidir lançar o erro ou retornar um array vazio
  }
};

export const getListPortfolioMonteCarloByUserId = async (): Promise<any[]> => {
  try {
    return await api.get2(url.GET_LIST_PORTFOLIO_MONTE_CARLO + '/' + getUidFromSessionStorage());
  } catch (error) {
    console.error('Error fetching summary by day data:', error);
    throw error;
  }
};

export const getMonteCarloByIdAndUserId = async (id: number): Promise<any> => {
  try {
    return await api.get2(url.GET_MONTE_CARLO_BY_MAGIC_NUMBER + '/' + getUidFromSessionStorage() + '/id/' + id);
  } catch (error) {
    console.error('Error fetching summary by day data:', error);
    throw error;
  }
};

export type MonteCarloData = {
  nome_simulacao: string;
  num_simulacoes: number;
  tipo_simulacao: number;
  files: any[];
};
export const createMonteCarloData = async (data: MonteCarloData): Promise<any> => {
  const formData = new FormData();
  formData.append('nome_simulacao', data.nome_simulacao);
  formData.append('num_simulacoes', data.num_simulacoes.toString());
  formData.append('tipo_simulacao', data.tipo_simulacao.toString());

  data.files.forEach((file) => {
    formData.append(`files`, file);
  });

  try {
    const apiClient = new APIClient();
    await apiClient.create2(url.CREATE_MONTE_CARLO + '/' + getUidFromSessionStorage() + '/simulation', formData);
  } catch (error) {
    console.error('Error creating Monte Carlo simulation:', error);
    throw error;
  }
};

export { groupDataByPeriod };

export const getRecentTransactionsData = async (uid: string, accountId: string | number, dates: string[], page: number = 1, pageSize: number = 15) => {
  const params = {
    userId: uid,
    login: String(accountId),
    startDate: dates[0],
    endDate: dates[1],
    page,
    pageSize,
  };

  try {
    return await api.get2(url.GET_RECENT_TRANSACTIONS, params);
  } catch (error) {
    console.error('Error fetching recent transactions:', error);
    throw error;
  }
};

export const getTransactionsData = async (params: any) => {
  // Dados fake para teste
  const fakeTransactions = Array.from({ length: 50 }, (_, index) => ({
    magicNumber: `${100000 + index}`,
    volume: Math.floor(Math.random() * 5) + 1,
    result: (Math.random() < 0.5 ? -1 : 1) * (Math.random() * 2000 + 100),
    date: new Date(2024, 0, Math.floor(Math.random() * 31) + 1).toISOString(),
  }));

  // Simulando delay de rede
  await new Promise((resolve) => setTimeout(resolve, 500));
  return fakeTransactions;
};

export const getDailyResults = async (userId: string, login: number): Promise<any> => {
  try {
    const response = await api.get2(url.GET_DAILY_RESULTS, {
      userId: userId,
      login: login,
    });
    return response;
  } catch (error) {
    console.error('Erro ao buscar resultados diários:', error);
    return {
      date: '',
      totalProfit: 0,
      totalOperations: 0,
      profitableOperations: 0,
      unprofitableOperations: 0,
      winRate: 0,
      maxProfit: 0,
      maxLoss: 0,
      magicNumbers: [],
      magicDetails: [],
    };
  }
};

export const getDailyTransactionsData = async (uid: string, accountId: string | number, startDate: string, endDate: string, magicNumber?: number, page: number = 1, pageSize: number = 7) => {
  const params = {
    userId: uid,
    login: String(accountId),
    startDate,
    endDate,
    magicNumber,
    page,
    pageSize,
  };

  try {
    return await api.get2(url.GET_DAILY_TRANSACTIONS, params);
  } catch (error) {
    console.error('Error fetching daily transactions:', error);
    throw error;
  }
};
