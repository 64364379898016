import React, { useState, useEffect } from 'react';
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import { RevenueCharts } from './../Dashboard';
import { useTranslation } from 'react-i18next';
import CountUp from 'react-countup';
import { useDispatch } from 'react-redux';
import { getCapitalCurveDataByPortfolioIdData } from '../../../slices/thunks';
import { useSelector } from 'react-redux';

type RevenueProps = {
  selectedDates: string[];
  selectDashboardPortfolioData: any;
  portfolioId: number;
};

const RevenuePortfolio = ({ selectedDates, selectDashboardPortfolioData, portfolioId }: RevenueProps) => {
  const { t } = useTranslation();
  const dispatch: any = useDispatch();

  const [chartData, setChartData] = useState<any>([]);
  const [chartColors, setChartColors] = useState<string[]>([]);
  const [period, setPeriod] = useState<string>('daily');

  const selectedAccounts = useSelector((state: any) => state.Layout.selectedAccounts || []);

  const generateRandomColor = () => {
    const colorNumber = Math.floor(Math.random() * 16777215);
    const colorHex = colorNumber.toString(16).padStart(6, '0');
    return '#' + colorHex;
  };

  const onChangeChartPeriod = (pType: string) => {
    setPeriod(pType);
    dispatch(getCapitalCurveDataByPortfolioIdData({ period: pType, selectedDates: selectedDates, selectedAccount: selectedAccounts, portfolioId }));
  };

  useEffect(() => {
    if (!selectDashboardPortfolioData?.data || selectDashboardPortfolioData.data.length === 0) {
      return;
    }

    let filteredData = [
      {
        name: selectDashboardPortfolioData.data[0].name || '',
        type: selectDashboardPortfolioData.data[0].type,
        data: selectDashboardPortfolioData.data[0].dataAccumulated,
        dataAccumulated: selectDashboardPortfolioData.data[0].data,
        periods: selectDashboardPortfolioData.data[0].periods,
      },
    ];

    setChartData(filteredData);

    const numberOfColorsNeeded = filteredData.length;
    const newChartColors = Array.from({ length: numberOfColorsNeeded }, generateRandomColor);

    setChartColors(newChartColors);
  }, [selectDashboardPortfolioData]);

  const validData = selectDashboardPortfolioData?.data?.[0]?.statistic;

  return (
    <React.Fragment>
      <Card>
        <CardHeader className="border-0 align-items-center d-flex">
          <h1 className="card-title mb-0 flex-grow-1">{t('CURVA_CAPITAL')}</h1>
          <div className="d-flex gap-1">
            <button
              type="button"
              className={`btn ${period === 'daily' ? 'btn-soft-primary' : 'btn-soft-secondary'} btn-sm`}
              onClick={() => {
                onChangeChartPeriod('daily');
              }}
            >
              ALL
            </button>
            <button
              type="button"
              className={`btn ${period === 'week' ? 'btn-soft-primary' : 'btn-soft-secondary'} btn-sm`}
              onClick={() => {
                onChangeChartPeriod('week');
              }}
            >
              1W
            </button>
            <button
              type="button"
              className={`btn ${period === 'month' ? 'btn-soft-primary' : 'btn-soft-secondary'} btn-sm`}
              onClick={() => {
                onChangeChartPeriod('month');
              }}
            >
              1M
            </button>
            <button
              type="button"
              className={`btn ${period === 'halfyear' ? 'btn-soft-primary' : 'btn-soft-secondary'} btn-sm`}
              onClick={() => {
                onChangeChartPeriod('halfyear');
              }}
            >
              6M
            </button>
            <button
              type="button"
              className={`btn ${period === 'year' ? 'btn-soft-primary' : 'btn-soft-secondary'} btn-sm`}
              onClick={() => {
                onChangeChartPeriod('year');
              }}
            >
              1Y
            </button>
          </div>
        </CardHeader>
        {validData && (
          <>
            <CardHeader className="p-0 border-0 bg-light-subtle">
              <Row className="g-0 text-center">
                <Col xs={6} sm={3}>
                  <div className="p-3 border border-dashed border-start-0">
                    <h5 className={`mb-1 ${selectDashboardPortfolioData.data[0].statistic.totalProfit >= 0 ? 'text-success' : 'text-danger'}`}>
                      <CountUp start={0} end={selectDashboardPortfolioData.data[0].statistic.totalProfit} duration={3} decimals={2} prefix="R$" separator="," />
                    </h5>
                    <p className="text-muted mb-0">Lucro total</p>
                  </div>
                </Col>
                <Col xs={6} sm={3}>
                  <div className="p-3 border border-dashed border-start-0">
                    <h5 className="mb-1 text-danger">
                      <CountUp start={0} decimals={2} end={selectDashboardPortfolioData.data[0].statistic.maxDrawdown} duration={3} prefix="R$" />
                    </h5>
                    <p className="text-muted mb-0">Máximo DrawnDown</p>
                  </div>
                </Col>
                <Col xs={6} sm={3}>
                  <div className="p-3 border border-dashed border-start-0">
                    <h5 className="mb-1 text-success">
                      <CountUp prefix="R$" start={0} end={selectDashboardPortfolioData.data[0].statistic.majorProfitInOneDay} duration={3} decimals={2} />
                    </h5>
                    <p className="text-muted mb-0">Maior Ganho diário</p>
                  </div>
                </Col>
                <Col xs={6} sm={3}>
                  <div className="p-3 border border-dashed border-start-0 border-end-0">
                    <h5 className={`mb-1 text-danger`}>
                      <CountUp start={0} end={selectDashboardPortfolioData.data[0].statistic.majorLossInOneDay} decimals={2} duration={3} prefix="R$" />
                    </h5>
                    <p className="text-muted mb-0">Maior Perda diária</p>
                  </div>
                </Col>
              </Row>
            </CardHeader>

            <CardBody className="mb-0 p-0 pb-2">
              <div className="w-100">
                <div dir="ltr">
                  <RevenueCharts series={chartData} categories={selectDashboardPortfolioData.data[0].periods} dataColors={JSON.stringify(chartColors)} />
                </div>
              </div>
            </CardBody>
          </>
        )}
      </Card>
    </React.Fragment>
  );
};

export default RevenuePortfolio;
