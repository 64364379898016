import { getTransactionsStart, getTransactionsSuccess, getTransactionsFail } from './reducer';
import { getRecentTransactionsData, getDailyTransactionsData } from '../../helpers/fakebackend_helper';
import { getUidFromSessionStorage } from 'slices/dashboard/thunk';
import { getDailyResultsData } from '../dashboard/thunk';

interface GetTransactionsParams {
  selectedDates: string[];
  selectedAccount: string | number;
  page: number;
  pageSize: number;
}

interface GetDailyTransactionsParams {
  selectedDates: string[];
  selectedAccount: string | number;
  magicNumber?: number | undefined;
  page: number;
  pageSize: number;
}

export const getTransactions = (params: GetTransactionsParams) => async (dispatch: any) => {
  try {
    dispatch(getTransactionsStart());

    const uid = getUidFromSessionStorage();
    if (!uid) return;

    const response = await getRecentTransactionsData(uid, params.selectedAccount, params.selectedDates, params.page, params.pageSize);

    dispatch(getTransactionsSuccess(response));
  } catch (error: any) {
    console.error('Error fetching transactions:', error);
    dispatch(getTransactionsFail(error));
  }
};

export const getDailyTransactions = (params: GetDailyTransactionsParams) => async (dispatch: any) => {
  try {
    dispatch(getTransactionsStart());

    const uid = getUidFromSessionStorage();
    if (!uid) return;

    const [startDate, endDate] = params.selectedDates;

    const response = await getDailyTransactionsData(uid, params.selectedAccount, startDate, endDate, params.magicNumber, params.page, params.pageSize);

    dispatch(getTransactionsSuccess(response));
  } catch (error: any) {
    console.error('Error fetching daily transactions:', error);
    dispatch(getTransactionsFail(error));
  }
};

export { getDailyResultsData };
