import { Card, CardBody, CardHeader, Col, Container, Row } from 'reactstrap';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { createSelector } from 'reselect';
import RevenueBot from './Revenue-Bot';
import { useSelector } from 'react-redux';
import { getCapitalCurveDataByMagicNumberData, getDailyResultsData } from 'slices/thunks';
import Section from '../Section';
import { useParams } from 'react-router-dom';
import { PaginationTableBot } from './ReactTable';
import { initSelectedDates } from '..';
import { getDailyTransactions } from 'slices/transactions/thunk';

const DashboardBot = () => {
  const { id } = useParams();
  document.title = `Dashboard | QuantARM`;
  const dispatch: any = useDispatch();

  const [selectedDates, setSelectedDates] = useState(initSelectedDates());

  const [transactionsData, setTransactionsData] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const pageSize = 7;

  const selectTransactionsData = createSelector(
    (state: any) => state.Transactions.transactions,
    (transactions) => ({
      transactions: transactions || {},
    }),
  );

  const { transactions } = useSelector(selectTransactionsData);

  const selectDashboardBotData = createSelector(
    (state: any) => state.DashboardBot.historyBotCurveData,
    (state: any) => state.Dashboard.dailyResults,
    (historyBotCurveData, dailyResults) => ({
      historyBotCurveData: historyBotCurveData ? historyBotCurveData : [],
      dailyResults: dailyResults || {},
    }),
  );

  const { historyBotCurveData, dailyResults } = useSelector(selectDashboardBotData);

  // Logging dos dados recebidos
  useEffect(() => {
    console.log('historyBotCurveData recebido:', historyBotCurveData);
  }, [historyBotCurveData]);

  const selectedAccounts: any = useSelector((state: any) => state.Layout.selectedAccounts || []);

  const updateSelectedDates = (dates: string[]) => {
    setSelectedDates(dates);
    sessionStorage.setItem('selectedDates', JSON.stringify(dates));
  };

  useEffect(() => {
    const fetchData = () => {
      if (selectedAccounts && selectedAccounts.length > 0) {
        dispatch(
          getCapitalCurveDataByMagicNumberData({
            period: 'daily',
            selectedDates,
            selectedAccount: selectedAccounts,
            roboId: Number(id),
          }),
        );
      }
    };

    fetchData();
  }, [dispatch, selectedDates, selectedAccounts, id]);

  useEffect(() => {
    const fetchTransactions = () => {
      if (selectedAccounts && selectedAccounts.length > 0) {
        dispatch(
          getDailyTransactions({
            selectedDates,
            selectedAccount: selectedAccounts,
            page: currentPage,
            pageSize,
            magicNumber: Number(id),
          }),
        );
      }
    };

    fetchTransactions();
  }, [dispatch, selectedDates, selectedAccounts, id, currentPage]);

  useEffect(() => {
    if (transactions?.data) {
      const formattedData = transactions.data.map((transaction: any) => ({
        magicNumber: id,
        date: transaction.date,
        valor: `R$ ${transaction.value?.toFixed(2) || '0.00'}`,
        status: transaction.status,
        contracts: transaction.contracts || 0,
      }));

      setTransactionsData(formattedData);

      if (transactions.totalPages) {
        setTotalPages(Number(transactions.totalPages));
      } else {
        setTotalPages(1);
      }
    } else {
      setTransactionsData([]);
      setTotalPages(1);
    }
  }, [transactions, id]);

  useEffect(() => {
    dispatch(getDailyResultsData({ selectedAccount: selectedAccounts }));
  }, [dispatch, selectedDates, selectedAccounts]);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col>
              <div className="h-100">
                <Section selectedDates={selectedDates} setSelectedDates={updateSelectedDates} dailyResults={dailyResults} title="Dashboard do Bot" />
                <Row>
                  <Col xl={12}>
                    <RevenueBot selectedDates={selectedDates} selectDashboardBotData={historyBotCurveData} robotId={Number(id)} />
                  </Col>
                </Row>
                <Row>
                  <Col lg={12}>
                    <Card>
                      <CardHeader>
                        <h5 className="card-title mb-0">Operações diária</h5>
                      </CardHeader>
                      <CardBody>
                        <PaginationTableBot data={transactionsData} pageSize={pageSize} pageIndex={currentPage} totalPages={totalPages} onPageChange={handlePageChange} />
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default DashboardBot;
