import React, { useEffect, useState, useMemo } from 'react';
import { Card, CardBody, CardHeader, Col, Container, Row } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { getTransactions, getDailyResultsData } from '../../slices/thunks';
import Section from '../Dashboard/Section';
import { TransactionTable } from './ReactTable';
import { initSelectedDates } from '../Dashboard';

// Selectors base
const selectTransactionsState = (state: any) => state.Transactions?.transactions;
const selectLayoutState = (state: any) => state.Layout?.selectedAccounts;
const selectDailyResultsState = (state: any) => state.Dashboard?.dailyResults;

// Selectors transformados
const transactionsSelector = createSelector([selectTransactionsState], (transactions) => {
  if (!transactions) return { transactions: [], totalPages: 0 };

  return {
    transactions:
      transactions.data?.map((t: any) => ({
        ...t,
        date: new Date(t.date).toISOString(), // Transformando a data
        value: Number(t.value), // Garantindo que value é número
      })) || [],
    totalPages: Math.ceil((transactions.total || 0) / (transactions.pageSize || 15)),
  };
});

const selectedAccountsSelector = createSelector([selectLayoutState], (accounts) => {
  if (!accounts) return [];

  // Se accounts for uma string (número da conta)
  if (typeof accounts === 'string') {
    return [{ id: accounts }];
  }

  // Se accounts for um objeto simples
  if (typeof accounts === 'object' && !Array.isArray(accounts)) {
    if (accounts.id) {
      return [accounts];
    }
    // Se for um objeto com números como chaves
    const accountNumber = Object.values(accounts).join('');
    return accountNumber ? [{ id: accountNumber }] : [];
  }

  return Array.isArray(accounts) ? accounts : [];
});

const dailyResultsSelector = createSelector([selectDailyResultsState], (dailyResults) => {
  return dailyResults || {};
});

const Transactions = () => {
  document.title = 'Transações | QuantARM';
  const dispatch: any = useDispatch();

  const [selectedDates, setSelectedDates] = useState(initSelectedDates());
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 15;

  // Usando os selectors memorizados
  const { transactions, totalPages } = useSelector(transactionsSelector);
  const selectedAccounts = useSelector(selectedAccountsSelector);
  const dailyResults = useSelector(dailyResultsSelector);

  // Calcula o resultado do dia
  const capitalCurveData = useMemo(() => {
    if (!transactions.length) return [];

    const dailyResults = transactions.reduce((acc: any, transaction: any) => {
      const date = transaction.date.split('T')[0]; // Usando split com 'T' para lidar com formato ISO
      if (!acc[date]) {
        acc[date] = {
          date,
          value: 0,
          accumulated: 0,
        };
      }
      acc[date].value += transaction.value;
      return acc;
    }, {});

    const sortedDates = Object.keys(dailyResults).sort();
    let accumulated = 0;
    const values = [];
    const accumulatedValues = [];

    for (const date of sortedDates) {
      accumulated += dailyResults[date].value;
      values.push(dailyResults[date].value);
      accumulatedValues.push(accumulated);
    }

    return [
      {
        data: values,
        periods: sortedDates,
        dataAccumulated: accumulatedValues,
      },
    ];
  }, [transactions]);

  const updateSelectedDates = (dates: string[]) => {
    setSelectedDates(dates);
    setCurrentPage(1); // Reset para primeira página quando mudar as datas
    sessionStorage.setItem('selectedDates', JSON.stringify(dates));
  };

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
  };

  useEffect(() => {
    if (selectedDates.length && selectedAccounts?.length > 0) {
      const account = selectedAccounts[0];
      let accountId: string | number;

      // Determinar o ID da conta corretamente
      if (typeof account === 'string') {
        accountId = account;
      } else if (typeof account === 'object' && account !== null) {
        accountId = account.id || Object.values(account).join('');
      } else {
        console.error('Invalid account format:', { account, selectedAccounts });
        return;
      }

      // Somente continuar se tiver um ID de conta válido
      if (accountId) {
        // Buscar transações
        dispatch(
          getTransactions({
            selectedDates,
            selectedAccount: accountId,
            page: currentPage,
            pageSize,
          }),
        );

        // Buscar resultados diários
        dispatch(getDailyResultsData({ selectedAccount: [accountId.toString()] }));
      }
    }
  }, [dispatch, selectedDates, selectedAccounts, currentPage]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col>
              <div className="h-100">
                <Section selectedDates={selectedDates} setSelectedDates={updateSelectedDates} capitalCurveData={capitalCurveData} dailyResults={dailyResults} title="Transações" />
                <Row>
                  <Col>
                    <Card>
                      <CardHeader>
                        <h4 className="card-title mb-0">Lista de Transações</h4>
                      </CardHeader>
                      <CardBody>
                        <TransactionTable data={transactions} pageSize={pageSize} pageIndex={currentPage} totalPages={totalPages} onPageChange={handlePageChange} />
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Transactions;
