import React, { useState, useEffect } from 'react';
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import { RevenueCharts } from './../Dashboard';
import { useTranslation } from 'react-i18next';
import CountUp from 'react-countup';
import { useDispatch } from 'react-redux';
import { getCapitalCurveDataByMagicNumberData } from '../../../slices/thunks';
import { useSelector } from 'react-redux';

type RevenueProps = {
  selectedDates: string[];
  selectDashboardBotData: any;
  robotId: number;
};

const RevenueBot = ({ selectedDates, selectDashboardBotData, robotId }: RevenueProps) => {
  const { t } = useTranslation();
  const dispatch: any = useDispatch();

  const [chartData, setChartData] = useState<any>([]);
  const [chartColors, setChartColors] = useState<string[]>([]);
  const [period, setPeriod] = useState<string>('daily');

  // Formatando dados localmente se necessário
  const formattedData = React.useMemo(() => {
    if (Array.isArray(selectDashboardBotData) && selectDashboardBotData.length > 0) {
      return { data: selectDashboardBotData };
    }
    return selectDashboardBotData;
  }, [selectDashboardBotData]);

  const selectedAccounts = useSelector((state: any) => state.Layout.selectedAccounts || []);

  const generateRandomColor = () => {
    const colorNumber = Math.floor(Math.random() * 16777215);
    const colorHex = colorNumber.toString(16).padStart(6, '0');
    return '#' + colorHex;
  };

  const onChangeChartPeriod = (pType: string) => {
    setPeriod(pType);
    dispatch(getCapitalCurveDataByMagicNumberData({ period: pType, selectedDates: selectedDates, selectedAccount: selectedAccounts, roboId: robotId }));
  };

  useEffect(() => {
    if (!formattedData?.data || formattedData.data.length === 0) {
      console.log('RevenueBot - Dados inválidos ou vazios, retornando');
      return;
    }

    try {
      let filteredData = [
        {
          name: formattedData.data[0].name || '',
          type: formattedData.data[0].type,
          data: formattedData.data[0].dataAccumulated,
          dataAccumulated: formattedData.data[0].data,
          periods: formattedData.data[0].periods,
        },
      ];

      setChartData(filteredData);

      const numberOfColorsNeeded = filteredData.length;
      const newChartColors = Array.from({ length: numberOfColorsNeeded }, generateRandomColor);

      setChartColors(newChartColors);
    } catch (error) {
      console.error('RevenueBot - Erro ao processar dados:', error);
    }
  }, [formattedData]);

  // Usar formattedData em vez de selectDashboardBotData diretamente
  const validData = formattedData?.data?.[0]?.statistic;

  return (
    <React.Fragment>
      <Card>
        <CardHeader className="border-0 align-items-center d-flex">
          <h1 className="card-title mb-0 flex-grow-1">{t('CURVA_CAPITAL')}</h1>
          <div className="d-flex gap-1">
            <button
              type="button"
              className={`btn ${period === 'daily' ? 'btn-soft-primary' : 'btn-soft-secondary'} btn-sm`}
              onClick={() => {
                onChangeChartPeriod('daily');
              }}
            >
              ALL
            </button>
            <button
              type="button"
              className={`btn ${period === 'week' ? 'btn-soft-primary' : 'btn-soft-secondary'} btn-sm`}
              onClick={() => {
                onChangeChartPeriod('week');
              }}
            >
              1W
            </button>
            <button
              type="button"
              className={`btn ${period === 'month' ? 'btn-soft-primary' : 'btn-soft-secondary'} btn-sm`}
              onClick={() => {
                onChangeChartPeriod('month');
              }}
            >
              1M
            </button>
            <button
              type="button"
              className={`btn ${period === 'halfyear' ? 'btn-soft-primary' : 'btn-soft-secondary'} btn-sm`}
              onClick={() => {
                onChangeChartPeriod('halfyear');
              }}
            >
              6M
            </button>
            <button
              type="button"
              className={`btn ${period === 'year' ? 'btn-soft-primary' : 'btn-soft-secondary'} btn-sm`}
              onClick={() => {
                onChangeChartPeriod('year');
              }}
            >
              1Y
            </button>
          </div>
        </CardHeader>
        {validData ? (
          <>
            <CardHeader className="p-0 border-0 bg-light-subtle">
              <Row className="g-0 text-center">
                <Col xs={6} sm={3}>
                  <div className="p-3 border border-dashed border-start-0">
                    <h5 className={`mb-1 ${formattedData.data[0].statistic.totalProfit >= 0 ? 'text-success' : 'text-danger'}`}>
                      <CountUp start={0} end={formattedData.data[0].statistic.totalProfit} duration={3} decimals={2} prefix="R$" separator="," />
                    </h5>
                    <p className="text-muted mb-0">Lucro total</p>
                  </div>
                </Col>
                <Col xs={6} sm={3}>
                  <div className="p-3 border border-dashed border-start-0">
                    <h5 className="mb-1 text-danger">
                      <CountUp start={0} decimals={2} end={formattedData.data[0].statistic.maxDrawdown} duration={3} prefix="R$" />
                    </h5>
                    <p className="text-muted mb-0">Máximo DrawnDown</p>
                  </div>
                </Col>
                <Col xs={6} sm={3}>
                  <div className="p-3 border border-dashed border-start-0">
                    <h5 className="mb-1 text-success">
                      <CountUp prefix="R$" start={0} end={formattedData.data[0].statistic.majorProfitInOneDay} duration={3} decimals={2} />
                    </h5>
                    <p className="text-muted mb-0">Maior Ganho diário</p>
                  </div>
                </Col>
                <Col xs={6} sm={3}>
                  <div className="p-3 border border-dashed border-start-0 border-end-0">
                    <h5 className={`mb-1 text-danger`}>
                      <CountUp start={0} end={formattedData.data[0].statistic.majorLossInOneDay} decimals={2} duration={3} prefix="R$" />
                    </h5>
                    <p className="text-muted mb-0">Maior Perda diária</p>
                  </div>
                </Col>
              </Row>
            </CardHeader>

            <CardBody className="mb-0 p-0 pb-2">
              <div className="w-100">
                <div dir="ltr">
                  <RevenueCharts series={chartData} categories={formattedData.data[0].periods} dataColors={JSON.stringify(chartColors)} />
                </div>
              </div>
            </CardBody>
          </>
        ) : (
          <CardBody>
            <div className="text-center">
              <p>Nenhum dado disponível para exibição.</p>
            </div>
          </CardBody>
        )}
      </Card>
    </React.Fragment>
  );
};

export default RevenueBot;
