import { createSlice } from '@reduxjs/toolkit';
import { getCapitalCurveData, getRevenueChartsData, getSummaryPortfolioByDayData, getRecentTransactions, getDailyResultsData } from '../dashboard/thunk';

interface DashboardState {
  revenueData: any;
  capitalCurveData: any;
  portfolioListData: any;
  recentTransactions: any;
  error: any;
  dailyResults: any;
}

export const initialState: DashboardState = {
  revenueData: [],
  capitalCurveData: [],
  portfolioListData: [],
  recentTransactions: {
    data: [],
    total: 0,
    page: 1,
    pageSize: 7,
    totalPages: 0,
  },
  error: {},
  dailyResults: {
    date: '',
    totalProfit: 0,
    totalOperations: 0,
    profitableOperations: 0,
    unprofitableOperations: 0,
    winRate: 0,
    maxProfit: 0,
    maxLoss: 0,
    magicNumbers: [],
    magicDetails: [],
  },
};

const DashboardSlice = createSlice({
  name: 'Dashboard',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getRevenueChartsData.fulfilled, (state, action) => {
      state.revenueData = action.payload;
    });
    builder.addCase(getRevenueChartsData.rejected, (state, action) => {
      state.error = action.error.message;
    });
    builder.addCase(getCapitalCurveData.fulfilled, (state, action) => {
      state.capitalCurveData = action.payload;
    });
    builder.addCase(getCapitalCurveData.rejected, (state, action) => {
      state.error = action.error.message;
    });

    builder.addCase(getSummaryPortfolioByDayData.fulfilled, (state, action) => {
      state.portfolioListData = action.payload;
    });
    builder.addCase(getSummaryPortfolioByDayData.rejected, (state, action) => {
      state.error = {};
    });

    builder.addCase(getRecentTransactions.fulfilled, (state, action) => {
      state.recentTransactions = action.payload;
    });
    builder.addCase(getRecentTransactions.rejected, (state, action) => {
      state.error = action.payload || null;
    });

    builder.addCase(getDailyResultsData.fulfilled, (state, action) => {
      state.dailyResults = action.payload;
    });
    builder.addCase(getDailyResultsData.rejected, (state, action) => {
      state.error = action.error.message;
    });
  },
});

export default DashboardSlice.reducer;
