import { createAsyncThunk } from '@reduxjs/toolkit';
import { getCapitalCurve, getSummaryByDay, getSummaryPortfolioByDay, groupDataByPeriod, getRecentTransactionsData, getDailyResults } from '../../helpers/fakebackend_helper';

export const getUidFromSessionStorage = () => {
  const authUserString = sessionStorage.getItem('authUser');
  if (authUserString) {
    const authUser = JSON.parse(authUserString);
    return authUser.uid || null;
  } else {
    throw new Error('authUser não encontrado no sessionStorage');
  }
};

const formatDate = (dates: string[]) => {
  for (let i = 0; i < dates.length; i++) {
    if (dates[i].includes('/')) {
      const dateArray = dates[i].split('/');
      dates[i] = `${dateArray[2]}-${dateArray[1].padStart(2, '0')}-${dateArray[0].padStart(2, '0')}`;
    } else if (dates[i].includes('T')) {
      dates[i] = dates[i].split('T')[0];
    }
  }
  return dates;
};

export const getRevenueChartsData = createAsyncThunk(
  'dashboard/getRevenueChartsData',
  async ({ period, robots, selectedDates, selectedAccount }: { period: string; robots?: string[]; selectedDates: string[]; selectedAccount: string[] }) => {
    const selectedRobots = robots || [];
    let response;
    let categories;
    let statisticsData;

    if (selectedAccount.length === 0) return Promise.resolve({ data: [], categories: [], statistics: {} });

    try {
      const uid = getUidFromSessionStorage();
      const all = await getSummaryByDay(uid, Number(selectedAccount[0]), formatDate(selectedDates));
      const { groupedData, categories: calculatedCategories, statistics } = await groupDataByPeriod(all, period, selectedRobots);

      switch (period) {
        case 'week':
          response = groupedData;
          categories = calculatedCategories!.map((week: string) => {
            const [year, weekNumber] = week.split('-');
            return `${weekNumber}-${year}`;
          });
          statisticsData = statistics;
          break;
        case 'month':
          response = groupedData;
          categories = calculatedCategories!.map((month: string) => {
            const [year, monthNumber] = month.split('-');
            return `${getMonthName(Number(monthNumber) - 1)} ${year}`;
          });
          statisticsData = statistics;
          break;
        case 'daily':
        case 'halfyear':
        case 'year':
        default:
          response = groupedData;
          categories = calculatedCategories;
          statisticsData = statistics;
          break;
      }
      return {
        data: response,
        categories,
        statistics: statisticsData,
      };
    } catch (error) {
      throw error;
    }
  },
);

export const getCapitalCurveData = createAsyncThunk(
  'dashboard/getCapitalCurveData',
  async ({ period, selectedDates, selectedAccount }: { period: string; selectedDates: string[]; selectedAccount: string[] }) => {
    if (selectedAccount.length === 0) return Promise.resolve({});

    try {
      const uid = getUidFromSessionStorage();
      const response = await getCapitalCurve(uid, Number(selectedAccount[0]), formatDate(selectedDates));
      return response;
    } catch (error) {
      throw error;
    }
  },
);

export const getSummaryPortfolioByDayData = createAsyncThunk(
  'dashboard/getSummaryPortfolioByDay',
  async ({ selectedDates, selectedAccount }: { selectedDates: string[]; selectedAccount: string[] }) => {
    try {
      const uid = getUidFromSessionStorage();
      if (!selectedAccount || selectedAccount.length === 0) return Promise.resolve([]);

      const all = await getSummaryPortfolioByDay(uid, Number(selectedAccount[0]), formatDate(selectedDates));

      const magicsPerPortfolio = all.reduce((acc, { id, magics }) => {
        const uniqueMagics = new Set(magics.split(', ').map((magic: number) => magic.toString().trim()));

        if (!acc[id]) {
          acc[id] = uniqueMagics;
        } else {
          uniqueMagics.forEach((magic) => acc[id].add(magic));
        }

        return acc;
      }, {});

      const portfolios = all.reduce((acc, { id, name, day, profitsum }) => {
        if (!acc[id]) {
          acc[id] = {
            id,
            name,
            magics: Array.from(magicsPerPortfolio[id]).join(', '),
            totalProfit: 0,
            accumulatedProfits: [],
            lastAccumulatedProfit: 0,
            maxGain: profitsum,
            maxLoss: profitsum,
            maxDrawdown: 0,
            maxDrawup: 0,
            drawdownAtual: 0,
            days: [],
          };
        }

        acc[id].days.push({
          day,
          profitsum,
        });

        const newAccumulatedProfit = acc[id].lastAccumulatedProfit + profitsum;
        acc[id].accumulatedProfits.push(newAccumulatedProfit);
        acc[id].lastAccumulatedProfit = newAccumulatedProfit;

        // Update statistics
        acc[id].totalProfit += profitsum;
        acc[id].maxGain = Math.max(acc[id].maxGain, profitsum);
        acc[id].maxLoss = Math.min(acc[id].maxLoss, profitsum);

        return acc;
      }, {});

      // Calculate max drawdown and drawup for each portfolio
      Object.values(portfolios).forEach((portfolio: any) => {
        let peak = 0;
        portfolio.accumulatedProfits.forEach((value: number) => {
          if (value > peak) {
            peak = value;
            portfolio.maxDrawup = Math.max(portfolio.maxDrawup, value);
          }
          const drawdown = peak - value;
          if (drawdown > portfolio.maxDrawdown) portfolio.maxDrawdown = drawdown;
          portfolio.drawdownAtual = drawdown;
        });
      });

      // // Convert the object to an array for the final output
      // const processedData = Object.values(portfolios).map((portfolio: any) => ({
      //   id: portfolio.id,
      //   name: portfolio.name,
      //   totalProfit: portfolio.totalProfit,
      //   magics: portfolio.magics,
      //   maxGain: portfolio.maxGain,
      //   maxLoss: portfolio.maxLoss,
      //   maxDrawdown: portfolio.maxDrawdown,
      //   maxDrawup: portfolio.maxDrawup,
      //   drawdownAtual: portfolio.drawdownAtual,
      //   accumulatedProfits: portfolio.accumulatedProfits,
      // }));

      // Convert the object to an array and sort by totalProfit in descending order
      const processedData = Object.values(portfolios)
        .map((portfolio: any) => ({
          id: portfolio.id,
          name: portfolio.name,
          totalProfit: portfolio.totalProfit,
          magics: portfolio.magics,
          maxGain: portfolio.maxGain,
          maxLoss: portfolio.maxLoss,
          maxDrawdown: portfolio.maxDrawdown,
          maxDrawup: portfolio.maxDrawup,
          drawdownAtual: portfolio.drawdownAtual,
          accumulatedProfits: portfolio.accumulatedProfits,
        }))
        .sort((a, b) => b.totalProfit - a.totalProfit);

      return processedData;
    } catch (error) {
      throw error;
    }
  },
);

export type TransactionResponse = {
  magicNumber: string;
  contracts: number;
  value: number;
  date: string;
  status: 'Gain' | 'Loss';
};

export const getRecentTransactions = createAsyncThunk('dashboard/getRecentTransactions', async ({ selectedDates, selectedAccount }: { selectedDates: string[]; selectedAccount: string[] }) => {
  if (selectedAccount.length === 0) return Promise.resolve({ data: [], total: 0, page: 1, pageSize: 7, totalPages: 0 });

  try {
    const uid = getUidFromSessionStorage();
    const response = await getRecentTransactionsData(
      uid,
      Number(selectedAccount[0]),
      selectedDates,
      1, // página fixa em 1 para transações recentes
      7, // limite fixo de 7 itens
    );
    return response;
  } catch (error) {
    throw error;
  }
});

// Função auxiliar para obter o nome do mês com base no número do mês (de 0 a 11)
function getMonthName(monthNumber: number): string {
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  return months[monthNumber];
}

export const getDailyResultsData = createAsyncThunk('dashboard/getDailyResults', async ({ selectedAccount }: { selectedAccount: string[] }) => {
  if (selectedAccount.length === 0)
    return Promise.resolve({
      date: '',
      totalProfit: 0,
      totalOperations: 0,
      profitableOperations: 0,
      unprofitableOperations: 0,
      winRate: 0,
      maxProfit: 0,
      maxLoss: 0,
      magicNumbers: [],
      magicDetails: [],
    });

  try {
    const uid = getUidFromSessionStorage();
    const response = await getDailyResults(uid, Number(selectedAccount[0]));
    return response;
  } catch (error) {
    throw error;
  }
});
